import {
  BANDS_DJS_TAXONOMY_KEY,
  BAR_SERVICES_TAXONOMY_KEY,
  CAKES_DESSERTS_TAXONOMY_KEY,
  CATERING_TAXONOMY_KEY,
  EXTRAS_TAXONOMY_KEY,
  FLORISTS_TAXONOMY_KEY,
  HAIR_MAKEUP_TAXONOMY_KEY,
  OFFICIANTS_TAXONOMY_KEY,
  PHOTOGRAPHERS_TAXONOMY_KEY,
  PLANNERS_TAXONOMY_KEY,
  VENUES_TAXONOMY_KEY,
  VIDEOGRAPHERS_TAXONOMY_KEY,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';

import _compact from 'lodash/compact';

import Logger from '~/util/logger';
import { centsToDollars } from '~/util/priceConversion';

import { VendorPricingFormValues } from './vendorPricingFormTypes';

export const calculateStartingPrice = (values: VendorPricingFormValues) => {
  switch (values.taxonomyKey) {
    case CAKES_DESSERTS_TAXONOMY_KEY:
    case CATERING_TAXONOMY_KEY:
    case HAIR_MAKEUP_TAXONOMY_KEY:
      return perPersonPricing(values);
    case BAR_SERVICES_TAXONOMY_KEY:
      return (
        perPersonPricing(values) ||
        (values.alcoholPerPersonStartPriceCents && values.minimumHeadCount
          ? centsToDollars(values.alcoholPerPersonStartPriceCents) * values.minimumHeadCount
          : null)
      );

    case BANDS_DJS_TAXONOMY_KEY:
      return (
        centsToDollars(values?.receptionStartPriceCents) ||
        centsToDollars(values?.ceremonyStartPriceCents) ||
        null
      );
    case VENUES_TAXONOMY_KEY:
      return Math.min(..._compact([values.peakStartPrice, values.offPeakStartPrice])) || null;
    case PLANNERS_TAXONOMY_KEY: {
      return 0;
    }
    case EXTRAS_TAXONOMY_KEY:
    case FLORISTS_TAXONOMY_KEY:
    case OFFICIANTS_TAXONOMY_KEY:
    case PHOTOGRAPHERS_TAXONOMY_KEY:
    case VIDEOGRAPHERS_TAXONOMY_KEY:
      return centsToDollars(values.fullStartPriceCents) || null;
    default:
      Logger.error('Unhandled vendor type in calculateStartingPrice');
      return null;
  }
};

const perPersonPricing = (values: {
  minimumSpendCents: number | null;
  minimumHeadCount: number | null;
  startPriceCentsPerPerson?: number | null;
  alcoholMinPerPersonCents?: number | null;
  startPriceCents?: number | null;
}) => {
  if (values.minimumSpendCents) return centsToDollars(values.minimumSpendCents);
  if (values.startPriceCents) return centsToDollars(values.startPriceCents);
  if (values.startPriceCentsPerPerson && values.minimumHeadCount) {
    return centsToDollars(values.startPriceCentsPerPerson) * values.minimumHeadCount;
  }
  if (values.alcoholMinPerPersonCents && values.minimumHeadCount) {
    return centsToDollars(values.alcoholMinPerPersonCents * values.minimumHeadCount);
  }

  return null;
};
