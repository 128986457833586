import { VendorTerms } from '~/types/types';

const officiantTerms: VendorTerms = {
  vendorType: {
    singular: 'wedding-officiant',
    plural: 'wedding-officiants',
  },
  introSection: {
    sectionTitle: 'Learn More',
  },
  editBioSection: {
    descriptionFieldLabel: 'About Your Business Or You',
    descriptionFieldPlaceholder:
      "Tell couples about your business to help them decide you're the perfect match.",
    nameField: 'Your Name or Team Name',
    nameFieldPlaceholder: 'Example: John Smith or Our Team',
    setsMeApartPlaceholder: 'officiants',
    titleField: 'Example: Head Officiant',
    actSizeLabel: 'Team Size',
  },
};

export default officiantTerms;
