import {
  BANDS_DJS_TAXONOMY_KEY,
  BAR_SERVICES_TAXONOMY_KEY,
  CAKES_DESSERTS_TAXONOMY_KEY,
  CATERING_TAXONOMY_KEY,
  FLORISTS_TAXONOMY_KEY,
  HAIR_MAKEUP_TAXONOMY_KEY,
  PHOTOGRAPHERS_TAXONOMY_KEY,
  VENUES_TAXONOMY_KEY,
  VIDEOGRAPHERS_TAXONOMY_KEY,
  SearchableVendorTaxonomyKey,
  EXTRAS_TAXONOMY_KEY,
  OFFICIANTS_TAXONOMY_KEY,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';

import { getVenueDetails } from '~/actions/util/storefrontDetails';
import { updateVenue } from '~/actions/vendors/storefrontActions';
import { updateBakerDetails } from '~/actions/vendors/vendorBakerActions';
import { updateBeauticianDetails } from '~/actions/vendors/vendorBeauticianActions';
import { updateCatererDetails } from '~/actions/vendors/vendorCatererActions';
import { updateExtrasDetails } from '~/actions/vendors/vendorExtrasActions';
import { updateFloristDetails } from '~/actions/vendors/vendorFloristActions';
import { updateMusicianDetails } from '~/actions/vendors/vendorMusicianActions';
import { updateOfficiantDetails } from '~/actions/vendors/vendorOfficiantActions';
import { updatePhotographerDetails } from '~/actions/vendors/vendorPhotographerActions';
import { updateVideographerDetails } from '~/actions/vendors/vendorVideographerActions';
import type { RootState } from '~/reducers';
import {
  getBakerDetails,
  getBeauticianDetails,
  getCatererDetails,
  getExtrasDetails,
  getFloristDetails,
  getMusicianDetails,
  getOfficiantDetails,
  getPhotographerDetails,
  getPhotographerElopmentEndPriceCents,
  getPhotographerElopmentStartPriceCents,
  getVideographerDetails,
  getVideographerElopmentEndPriceCents,
  getVideographerElopmentStartPriceCents,
} from '~/selectors/vendorStorefrontSelectors';
import type { VenueDetails } from '~/types/responseTypes';
import { VendorStorefrontDetails } from '~/types/storefrontDetails';

import {
  ALCOHOL_STARTING_PRICE,
  BAKER_MINIMUM_SPEND,
  BAKER_MIN_HEADCOUNT,
  BAKER_PER_SERVING_PRICE,
  BEAUTICIAN_DAY_OF_SERVICES_FIELD,
  BEAUTICIAN_MINIMUM_SPEND,
  BEAUTICIAN_MIN_SERVICE_COUNT,
  BEVERAGE_STARTING_PRICE,
  BOUQUET_PRICE_RANGE,
  CATERER_MINIMUM_SPEND,
  CENTERPIECE_PRICE_RANGE,
  ELOPEMENT_PRICING,
  FOOD_STARTING_PRICE,
  FULL_CEREMONY_PRICING,
  FULL_RECEPTION_STARTING_PRICE,
  FULL_WEDDING_PRICE,
  MIN_HEAD_COUNT,
  OFF_PEAK_DEFAULT,
  PEAK_DEFAULT,
  PRICING_CALCULATOR,
  VENUE_FOOD_AND_BEV_STARTING_PRICE,
  VENUE_MIN_FOOD_AND_BEV,
  VENUE_RENTAL_STARTING_PRICE_OFF_PEAK,
  VENUE_RENTAL_STARTING_PRICE_ON_PEAK,
} from './inputs/vendorPricingFields';
import { VendorPricingSection, VendorPricingFormValues } from './vendorPricingFormTypes';

/**
 * vendorPricing meta data.
 *
 * @module vendorPricingMeta
 * @see src/pages/vendors/Storefront/editPages/BasicInfoV2.jsx
 * @see src/reducers/vendorStorefrontReducer.js
 */
const DEFAULT_VENDOR_PRICING: VendorPricingSection = {
  fields: [OFF_PEAK_DEFAULT, PEAK_DEFAULT, PRICING_CALCULATOR()],
};

const PHOTOGRAPHER_PRICING = (): VendorPricingSection => ({
  vendorDetails: {
    selector: getPhotographerDetails,
    updater: updatePhotographerDetails,
  },
  fields: [
    ELOPEMENT_PRICING(getPhotographerElopmentStartPriceCents, getPhotographerElopmentEndPriceCents),
    FULL_WEDDING_PRICE,
    PRICING_CALCULATOR(),
  ],
});

const VIDEOGRAPHER_PRICING = (): VendorPricingSection => ({
  vendorDetails: {
    selector: getVideographerDetails,
    updater: updateVideographerDetails,
  },
  fields: [
    ELOPEMENT_PRICING(getVideographerElopmentStartPriceCents, getVideographerElopmentEndPriceCents),
    FULL_WEDDING_PRICE,
    PRICING_CALCULATOR(),
  ],
});

const CATERER_PRICING = (): VendorPricingSection => {
  return {
    vendorDetails: {
      selector: getCatererDetails,
      updater: updateCatererDetails,
    },
    fields: [
      CATERER_MINIMUM_SPEND,
      FOOD_STARTING_PRICE,
      MIN_HEAD_COUNT,
      ALCOHOL_STARTING_PRICE,
      PRICING_CALCULATOR(
        'Zola uses your minimum spend. If you don’t have one, we use your per person starting price and minimum head count.'
      ),
    ],
  };
};

const BAR_SERVICES_PRICING = (): VendorPricingSection => ({
  vendorDetails: {
    selector: getCatererDetails,
    updater: updateCatererDetails,
  },
  fields: [
    CATERER_MINIMUM_SPEND,
    BEVERAGE_STARTING_PRICE,
    ALCOHOL_STARTING_PRICE,
    MIN_HEAD_COUNT,
    PRICING_CALCULATOR(
      'Zola uses your minimum spend. If you don’t have one, we use your per person starting price and minimum head count.'
    ),
  ],
});

const FLORIST_PRICING = (): VendorPricingSection => ({
  vendorDetails: {
    selector: getFloristDetails,
    updater: updateFloristDetails,
  },
  fields: [FULL_WEDDING_PRICE, BOUQUET_PRICE_RANGE, CENTERPIECE_PRICE_RANGE, PRICING_CALCULATOR()],
});

const MUSICIAN_PRICING = (): VendorPricingSection => ({
  vendorDetails: {
    selector: getMusicianDetails,
    updater: updateMusicianDetails,
  },
  fields: [
    {
      Component: 'AtLeastOneField',
      props: [FULL_CEREMONY_PRICING, FULL_RECEPTION_STARTING_PRICE].map(({ props }) => props),
    },
    PRICING_CALCULATOR(
      'Zola uses your minimum reception price. If you only play ceremonies, we use your minimum ceremony price.'
    ),
  ],
});

const BAKER_PRICING = (): VendorPricingSection => ({
  vendorDetails: {
    selector: getBakerDetails,
    updater: updateBakerDetails,
  },
  fields: [
    BAKER_PER_SERVING_PRICE,
    BAKER_MIN_HEADCOUNT,
    BAKER_MINIMUM_SPEND,
    PRICING_CALCULATOR(
      'Zola uses your minimum spend. If you don’t have one, we use your per person starting price and minimum serving count.'
    ),
  ],
});

const BEAUTICIAN_PRICING = (): VendorPricingSection => ({
  vendorDetails: {
    selector: getBeauticianDetails,
    updater: updateBeauticianDetails,
  },
  fields: [
    BEAUTICIAN_DAY_OF_SERVICES_FIELD,
    BEAUTICIAN_MIN_SERVICE_COUNT,
    BEAUTICIAN_MINIMUM_SPEND,
    PRICING_CALCULATOR(
      'Zola uses your minimum spend. If you don’t have one, we use your per person starting price and minimum service count.'
    ),
  ],
});

const VENUE_PRICING = (): VendorPricingSection => ({
  vendorDetails: {
    // @ts-expect-error
    selector: getVenueDetails,
    // @ts-expect-error
    updater: (venueDetails: VenueDetails) => updateVenue(venueDetails, {}),
  },
  fields: [
    OFF_PEAK_DEFAULT,
    PEAK_DEFAULT,
    PRICING_CALCULATOR(),
    VENUE_MIN_FOOD_AND_BEV,
    VENUE_FOOD_AND_BEV_STARTING_PRICE,
    VENUE_RENTAL_STARTING_PRICE_OFF_PEAK,
    VENUE_RENTAL_STARTING_PRICE_ON_PEAK,
  ],
});

const OFFICIANT_PRICING = (): VendorPricingSection => ({
  vendorDetails: {
    selector: getOfficiantDetails,
    updater: updateOfficiantDetails,
  },
  fields: [FULL_WEDDING_PRICE, PRICING_CALCULATOR()],
});

const EXTRAS_PRICING = (): VendorPricingSection => ({
  vendorDetails: {
    selector: getExtrasDetails,
    updater: updateExtrasDetails,
  },
  fields: [FULL_WEDDING_PRICE, PRICING_CALCULATOR()],
});

/**
 * Gets pricing meta-data for the BasicInfo screen.
 */
export const getVendorPricing = (
  taxonomyKey: SearchableVendorTaxonomyKey
): VendorPricingSection => {
  switch (taxonomyKey) {
    case BANDS_DJS_TAXONOMY_KEY:
      return MUSICIAN_PRICING();
    case BAR_SERVICES_TAXONOMY_KEY:
      return BAR_SERVICES_PRICING();
    case CAKES_DESSERTS_TAXONOMY_KEY:
      return BAKER_PRICING();
    case CATERING_TAXONOMY_KEY:
      return CATERER_PRICING();
    case EXTRAS_TAXONOMY_KEY:
      return EXTRAS_PRICING();
    case FLORISTS_TAXONOMY_KEY:
      return FLORIST_PRICING();
    case HAIR_MAKEUP_TAXONOMY_KEY:
      return BEAUTICIAN_PRICING();
    case OFFICIANTS_TAXONOMY_KEY:
      return OFFICIANT_PRICING();
    case PHOTOGRAPHERS_TAXONOMY_KEY:
      return PHOTOGRAPHER_PRICING();
    case VENUES_TAXONOMY_KEY:
      return VENUE_PRICING();
    case VIDEOGRAPHERS_TAXONOMY_KEY:
      return VIDEOGRAPHER_PRICING();
    default:
      return DEFAULT_VENDOR_PRICING;
  }
};

export const getInitialValues = (
  storefrontDetails: VendorStorefrontDetails,
  state: RootState
): VendorPricingFormValues | undefined => {
  const { priceRangeVisible, taxonomyKey } = storefrontDetails;

  const vendorSpecificPricing = getVendorPricing(taxonomyKey).fields.reduce((acc, field) => {
    if (field.Component === 'PriceRangeFields') {
      acc[field.props.minimum.name] = field.props.minimum.selector(state) || null;
      acc[field.props.maximum.name] = field.props.maximum.selector(state) || null;
    }

    if (field.Component === 'SinglePriceField') {
      acc[field.props.name] = field.props.selector(state) || null;
    }

    if (field.Component === 'AtLeastOneField') {
      field.props.forEach((prop) => {
        acc[prop.name] = prop.selector(state) || null;
      });
    }
    return acc;
  }, {} as Record<string, number | null>);

  return {
    taxonomyKey,
    priceRangeVisible: priceRangeVisible ? 'true' : 'false',
    ...vendorSpecificPricing,
  } as VendorPricingFormValues;
};
